import { Col, Flex } from "antd";
import { Link } from "react-router-dom";
import "../App.css";
import LogoSVG from "./LogoSvg";

const HeaderNew = ({ selected }) => {
  return (
    <Flex className="header-row" align="center" justify="space-evenly">
      <Col xs={21} sm={21} md={9} lg={9} className="column-left ">
        {" "}
        <LogoSVG className="header-logo"></LogoSVG>
      </Col>
      <Col className="column-mid"></Col>
      <Col className="column-right">
        <div>
          <ul className="ant-menu-item">
            <Flex justify="flex-start">
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                className={
                  selected === "home"
                    ? "ant-menu-item-selected"
                    : "ant-menu-item"
                }
              >
                {" "}
                Home
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                to="/dashboard"
                className={
                  selected === "onlineTool"
                    ? "ant-menu-item-selected"
                    : "ant-menu-item"
                }
              >
                {" "}
                Online Tool{" "}
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                to="/Methodology"
                className={
                  selected === "methodology"
                    ? "ant-menu-item-selected"
                    : "ant-menu-item"
                }
              >
                {" "}
                Methodology
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                to="/Team"
                className={
                  selected === "team"
                    ? "ant-menu-item-selected"
                    : "ant-menu-item"
                }
              >
                {" "}
                Team{" "}
              </Link>
            </Flex>
          </ul>
        </div>
      </Col>
    </Flex>
  );
};
export default HeaderNew;
