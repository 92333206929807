/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import "../assets/css/normalize.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/main.css";
import { BackTop, Row, Col } from "antd";

import Footer from "./Footer";
import HeaderNew from "./HeaderNew";
import imgJag from "../assets/img/Jag Chhatwal.jpg";
import imgChristina from "../assets/img/Christina Washington.jpg";
import imgKalyani from "../assets/img/Kalyani Sonawane.jpg";
import imgKathleen from "../assets/img/Kathleen Moore.jpg";
import imgTara from "../assets/img/Tara Castellano.jpg";
import imgTurgay from "../assets/img/Turgay Ayer.png";
import imgAndy from "../assets/img/Andy Surinach.jpg";
import imgAsim from "../assets/img/Asim Altunbas.jpg";
import imgYasin from "../assets/img/Yasin Yildiz.jpg";
import imgAndrew from "../assets/img/Andrew ElHabr.jpg";
import imgDeniz from "../assets/img/Deniz Balta.jpg";
import imgEzgi from "../assets/img/Ezgi Berksoy.jpg";
import TeamCard from "../components/TeamCard";
import imgJie from "../assets/img/Jie.jpg";
import imgJenny from "../assets/img/Jenny.jpg";
import imgFernando from "../assets/img/Fernando Musa.png";
import imgLeslie from "../assets/img/Leslie Randall.png";
import imgCharles from "../assets/img/Charles Leath.jpg";

function Team() {
  const title1 = "Tara Castellano, MD";
  const subTitle1 = " ";
  const text1 = (
    <p>
      Dr. Tara Castellano is a Gynecologic Oncologist with the Louisiana State
      University Health Sciences Center Department of Obstetrics and Gynecology.
      Dr. Castellano is fellowship trained at the University of Oklahoma in
      advanced pelvic surgery, oncologic care and advanced therapeutics for
      gynecologic malignancies. She is an active member of the Society of
      Gynecologic Oncology and recently served on the 2020 Annual Program
      Committee. Dr. Castellano's research interests include clinical trial
      design, quality initiative models, identification and elimination of
      health disparities and improvements in cancer care delivery. Dr.
      Castellano is a current Robert A Winn Scholar in Diversity in Clinical
      Trials Program scholar, an awardee of the NRG underrepresented minority
      mentorship grant, and GOG new investigator awardee. She has published and
      nationally presented dozens of scientific abstracts and articles over the
      past several years and is actively engaged in research. Dr. Castellano is
      excited to improve care and treatment options as well as expand clinical
      trial access to women with gynecologic cancers in south Louisiana.
    </p>
  );
  const img1 = imgTara;

  const title3 = "Kathleen Moore, MD, MS";
  const subTitle3 = "";
  const text3 = (
    <p>
      Dr. Kathleen N. Moore is an Associate Professor in the Section of
      Gynecologic Oncology; the Jim and Christy Everest Endowed Chair in Cancer
      Research; and the Director of the Oklahoma TSET Phase I Program for the
      Stephenson Cancer Center at the University of Oklahoma College of
      Medicine. She attended medical school at the University of Washington
      School of Medicine and completed her residency in obstetrics and
      gynecology at the University Health Center of Pittsburgh. Dr. Moore
      completed a fellowship in gynecologic oncology at the University of
      Oklahoma Health Sciences Center while simultaneously earning a master's
      degree in epidemiology. She is board certified in obstetrics and
      gynecology as well as gynecologic oncology and hospice and palliative
      care. She serves as the Associate Director of Clinical Research and
      Medical Director of the Clinical Trials Office for the Stephenson Cancer
      Center. She has published over 200 peer-reviewed publications and serves
      on the editorial board for four academic publications. She has a clinical
      research interest in drug development and phase I trials.
    </p>
  );
  const img3 = imgKathleen;

  const title5 = "Christina Washington, MD";
  const subTitle5 = " ";
  const text5 = (
    <p>
      Dr. Washington is an Assistant Professor in the Department of OBGYN,
      Division of Gynecologic Oncology at the University of Oklahoma Health
      Sciences Center (OUHSC). Dr. Washington obtained her medical degree at the
      University of Medicine and Dentistry of New Jersey Robert Wood Johnson
      Medical School. She completed her residency training at the University of
      Pennsylvania Health System – Pennsylvania Hospital in Philadelphia PA,
      where she served as Administrative Chief Resident. After completion of
      residency, Dr. Washington completed a fellowship in Gynecologic Oncology
      at the Stephenson Cancer Center at the University of Oklahoma in Oklahoma
      City. As a fellow, Dr Washington was enrolled in the master’s in clinical
      and translational science program. Her master’s and fellowship thesis is
      focused on evaluating food deserts, food swamps, and water supply in the
      state of Oklahoma and how that relates to gynecologic cancer patients
      outcomes. Her research interests include cancer prevention, cancer
      disparities, and advocating for vulnerable populations. By evaluating and
      addressing food insecurity in gynecologic cancer patients, she hopes to
      improve cancer related outcomes in this patient population.
    </p>
  );
  const img5 = imgChristina;

  const title2 = "Turgay Ayer, PhD";
  const subTitle2 = " ";
  const text2 = (
    <p>
      Turgay Ayer is the Virginia C. and Joseph C. Mello Chair and the Research
      Director for Healthcare Analytics and Business Intelligence in the Center
      for Health & Humanitarian Systems at Georgia Tech and a principal
      scientist at Value Analytics Labs. In addition, Ayer holds a courtesy
      appointment at Emory Medical School and serves as a senior scientist at
      the Centers for Disease Control and Prevention (CDC). Ayer’s research
      focuses on health economics modeling, global market access, real world
      evidence, data science, and machine learning. Ayer’s research findings
      have been published in top-tier business, engineering, medical, and health
      policy journals and are widely covered in popular media outlets, including
      the Wall Street Journal, Fox Tv, Washington Post, NPR, and US News, among
      others. Research by Ayer and co-authors has made a policy impact by
      influencing lung cancer drug pricing and coverage decisions in the U.K.,
      by changing how medical surplus products are allocated to the developing
      world by a major medical surplus recovery organization, and by changing
      and improving blood collection operations in the nation by the American
      Red Cross. Ayer has published over 80 journal papers and over 250
      conference abstracts.
    </p>
  );
  const img2 = imgTurgay;

  const title4 = "Jag Chhatwal, PhD";
  const subTitle4 = "";
  const text4 = (
    <p>
      Jag Chhatwal is an Associate Professor at Harvard Medical School and
      Director of the Institute for Technology Assessment at Massachusetts
      General Hospital. Chhatwal is also a principal scientist at Value
      Analytics Labs. His research is centered in decision science, data
      analytics, outcomes research, and health economics. Chhatwal has
      co-authored over 120 original research articles and editorials in
      peer-reviewed journals. His work has been cited in leading media outlets,
      including CNN, Forbes, MSNBC, National Public Radio, New York Times, and
      Wall Street Journal. He has over 15 years of experience in developing
      data/decision models to evaluate disease outcomes. His work utilizes
      innovative methods to disseminate the findings of decision models to
      policymakers using interactive online platforms.
    </p>
  );
  const img4 = imgJag;

  const title6 = "Kalyani Sonawane, PhD";
  const subTitle6 = "";
  /*  const text6 = (
      <p>
        Dr. Sonawane is an Assistant Professor of Health Services Research and Co
        Director of Clinical Analytics and Decision Science Lab at The University
        of Texas Health Science Center at Houston (UTHealth) School of Public
        Health. Her expertise is in performing population health analytics using
        high dimensional healthcare data to inform clinical practice and policy.
        She has conducted several real-world studies using health insurance
        claims, nationwide surveys, electronic medical records, and patient
        registries to study prevention and treatment strategies and to evaluate
        their downstream impact on population health outcomes. Dr. Sonawane has
        published in prestigious clinical journals including the Lancet Public
        Health, Annals of Internal Medicine, and, JAMA Network Open. Her research
        has been featured in media outlets including CNN, TIME, Forbes, NPR News,
        NBC News, and CNBC.
      </p>
    ); */
  const img6 = imgKalyani;

  const title65 = "Jie Ting, PhD, MSPH";
  const subTitle65 = "";
  const text65 = (
    <p>
      Jie Ting is a Senior Director of Value & Evidence Oncology at Pfizer Inc.
      Her work focuses on outcomes research, health economics, and decision
      analytics to provide evidence-based guidance on improving care
      and quality-of-life for patients. She received her PhD in Epidemiology
      from University of North Carolina at Chapel Hill, and completed her
      postdoctoral fellowship at University of California, San Francisco. Ting
      has published over 25 peer-reviewed studies across multiple disease areas.
    </p>
  );
  const img65 = imgJie;

  const title7 = "Andy Surinach, MPH";
  const subTitle7 = "";
  /*  const text7 = (
      <p>
        Andy leads the Client Analytics at Genesis Research. He has over 9 years
        of experience leading and performing healthcare analytics across a variety
        of therapeutic areas to generate real-world evidence from a variety of
        real-world data sources including, but not limited to, administrative
        healthcare claims, electronic health records, registries, and patient and
        physician surveys. He received his MPH in Epidemiology with a certificate
        in Biostatistics from The Columbia Mailman School of Public Health and his
        BS in Chemical Engineering from the Columbia's Fu Foundation School of
        Engineering and Applied Science.
      </p>
    ); */
  const img7 = imgAndy;

  const title8 = "Asim Altunbas, MBA";
  const subTitle8 = "";
  const text8 = (
    <p>
      Asim Altunbas is a Director of Operations at Value Analytics Labs. He has
      been working in R&D projects for 12 years in mostly managerial levels in a
      variety of fields, such as qualified electronic certificates, secure
      e-signature infrastructures, quality assurance systems, periodical
      literature, etc. He also has experience in web technologies and software
      to generate visuals and effects for cross-platform solutions. He possesses
      a BS degree in Industrial Engineering and an MS degree in Business
      Administration.
    </p>
  );
  const img8 = imgAsim;

  const title9 = "Yasin Yildiz, MS";
  const subTitle9 = "";
  /* const text9 = (
     <p>
       Yasin was a Data Scientist and UI Developer at Value Analytics Labs. He
       has more than 5 years of experience in simulation modeling and data
       analysis. Besides disease simulation and cost effectiveness studies, he
       also has experience in online tool development for various projects. He
       received his BS and MS degrees in Industrial Engineering from Bogazici
       University, Istanbul.
     </p>
   ); */
  const img9 = imgYasin;

  const title10 = "Andrew ElHabr, PhD";
  const subTitle10 = "";
  const text10 = (
    <p>
      Andrew ElHabr is a Data Scientist at Value Analytics Labs. He has more
      than 5 years of experience using statistical modeling with a variety of
      real-world data sources, including data from administrative claims
      databases, registries, and electronic medical records. He received his MS
      and PhD in Operations Research from the Georgia Institute of Technology,
      and co-lectures an introductory course on big data at Emory University.
    </p>
  );
  const img10 = imgAndrew;

  const title11 = "Ezgi Berksoy, MS";
  const subTitle11 = "";
  const text11 = (
    <p>
      Ezgi Berksoy is a Data Scientist at Value Analytics Labs. She has more
      than 4 years of experience in modeling and data analysis. She received her
      BS degree in Economics from Bogazici University and MS in Data Science
      from Ozyegin University, Istanbul.
    </p>
  );
  const img11 = imgEzgi;

  const title12 = "Deniz Balta, BS";
  const subTitle12 = "";
  const text12 = (
    <p>
      Deniz Balta is a Data Scientist and UI Developer at Value Analytics Labs.
      She has experience working with data visualization and web tool
      development. She received her BS degree in Computer Science and
      Engineering from Sabanci University, İstanbul.
    </p>
  );
  const img12 = imgDeniz;

  const title13 = "Yitong (Jenny) Zhang, PhD ";
  const subTitle13 = "";
  const text13 = (
    <p>
      Jenny Zhang is the Global Medical Strategy Lead for gynecologic oncology
      at Pfizer Inc. Jenny was trained as a cancer research scientist at the
      University of Washington. She is honored to be part of the journey with
      patients, survivors, caregivers, doctors and researchers, to strive
      towards a future where the “C” stands for “cure” for people living with a
      cancer diagnosis.{" "}
    </p>
  );
  const img13 = imgJenny;

  const title14 = "Fernanda Musa, MD ";
  const subTitle14 = "";
  const text14 = (
    <p>
      Dr. Musa is a Gynecologic Oncologist at Swedish Cancer Institute in
      Seattle. She obtained her medical training at Columbia University in New
      York and completed her residency in OBGYN at New York Presbyterian
      Hospital – Cornell. Her fellowship in Gynecologic Oncology was completed
      at NYU, where she also obtained an MS degree in Clinical Investigation
      with a thesis focusing on mTOR inhibitors and their use in ovarian cancer.
      Dr. Musa directs clinical trials in Gynecologic Oncology at SCI, where she
      strives to bring access to clinical trials to all members of her
      community. She serves as Fellowship Director for the Advanced Pelvic
      Surgery fellowship at Swedish. She is a member of the SGO board of
      directors and also participates on the board of directors for the Rivkin
      Foundation. She is on the editorial board of Gynecologic Oncology Reports,
      an Elsevier publication. Her research interests include drug development
      and biomarker targeted therapy in gynecologic cancers. She is committed to
      improving generalizability of clinical trials through enrollment of
      diverse populations.
    </p>
  );
  const img14 = imgFernando;

  const title15 = "Charles A. Leath, III, MD, MSPH";
  const subTitle15 = "";
  const text15 = (
    <p>
      Dr. Leath is the Ellen Gregg Shook Culverhouse Chair in Gynecologic
      Oncology and Division Director at the University of Alabama at Birmingham.
      Following medical school, he completed both residency in Obstetrics and
      Gynecology and fellowship in Gynecologic Oncology at the University of
      Alabama at Birmingham. Currently Dr. Leath is the Chair of the NRG
      Oncology Cervical & Vulvar Cancer Committee and a former member of the
      Safety Committee and is the National Principal Investigator for NRG
      clinical trial GY006 evaluating the addition of triapine to standard
      chemoradiation for locally advanced cervical cancer. Dr. Leath is board
      certified in both Obstetrics & Gynecology and Gynecologic Oncology and
      holds a Masters of Science in Public Health (MSPH) degree from the
      University of Alabama School of Public Health. In addition to being a
      member of the National Comprehensive Cancer Center (NCCN) Ovarian Cancer
      Guidelines, he is one of the Discussion writing committee members. He is
      the institutional principal investigator for the Lead Academic
      Participating Site (LAPS) grant as part of the National Cancer Institutes’
      National Clinical Trial Network, the Chair of the O’Neal Comprehensive
      Cancer Center Protocol Review and Monitoring Committee and is an
      investigator on the combined Johns Hopkins-UAB Cervical Cancer SPORE. Dr.
      Leath is actively involved in clinical research in the management of
      cervical, endometrial and ovarian cancers, as well as clinical trials for
      all aspects of gynecologic cancer, and is an author or co-author of over
      150 peer-reviewed publications.{" "}
    </p>
  );
  const img15 = imgCharles;

  const title16 = "Leslie Randall, MD, MAS";
  const subTitle16 = "";
  const text16 = (
    <p>
      Dr. Leslie Randall is the Dianne Harris Wright Professor of Gynecologic
      Oncology at Virginia Commonwealth University and the National Cancer
      Institute–designated Massey Cancer Center. She completed her medical
      training at the University of Louisville and her gynecologic oncology
      subspecialty training at the University of California, Irvine, where she
      remained on the faculty for 10 years. She is board-certified to deliver
      comprehensive medical and surgical care to women with all gynecologic
      cancers. Dr. Randall holds a master’s degree in advanced clinical
      research, serves as the principal investigator for multiple clinical
      trials, and is on the scientific steering committees of several other
      national and international trials. She serves on the NRG Oncology Group
      Ovarian Cancer and Developmental Therapeutics Committees and sits on the
      Board of Directors for the Gynecologic Oncology Group (GOG)
      Foundation. Dr. Randall is also the Cervical Cancer Clinical Trial Advisor
      for the GOG Partners Leadership Committee.
    </p>
  );
  const img16 = imgLeslie;

  const returnValue = (
    <>
      <HeaderNew selected="team" />
      <div className="team-container">
        <Row justify="start">
          <div className="team-header-wrapper">Team</div>
        </Row>
        <Row justify="space-between">
          <Col sm={24} md={12} id="team1">
            <Row justify="start">
              <TeamCard
                title={title1}
                subTitle={subTitle1}
                text={text1}
                img={img1}
              />
            </Row>
          </Col>

          <Col sm={24} md={12} id="team2">
            <Row justify="start">
              <TeamCard
                title={title2}
                subTitle={subTitle2}
                text={text2}
                img={img2}
              />
            </Row>
          </Col>

          <Col md={12} id="team3">
            <Row justify="start">
              <TeamCard
                title={title3}
                subTitle={subTitle3}
                text={text3}
                img={img3}
              />
            </Row>
          </Col>

          <Col md={12} id="team4">
            <Row justify="start">
              <TeamCard
                title={title4}
                subTitle={subTitle4}
                text={text4}
                img={img4}
              />
            </Row>
          </Col>
          <Col md={12} id="team5">
            <Row justify="start">
              <TeamCard
                title={title5}
                subTitle={subTitle5}
                text={text5}
                img={img5}
              />
            </Row>
          </Col>
          <Col md={12} id="team14">
            <Row justify="start">
              <TeamCard
                title={title14}
                subTitle={subTitle14}
                text={text14}
                img={img14}
              />
            </Row>
          </Col>
          <Col md={12} id="team15">
            <Row justify="start">
              <TeamCard
                title={title15}
                subTitle={subTitle15}
                text={text15}
                img={img15}
              />
            </Row>
          </Col>
          <Col md={12} id="team16">
            <Row justify="start">
              <TeamCard
                title={title16}
                subTitle={subTitle16}
                text={text16}
                img={img16}
              />
            </Row>
          </Col>
          <Col md={12} id="team65">
            <Row justify="start">
              <TeamCard
                title={title65}
                subTitle={subTitle65}
                text={text65}
                img={img65}
              />
            </Row>
          </Col>
          <Col md={12} id="team13">
            <Row justify="start">
              <TeamCard
                title={title13}
                subTitle={subTitle13}
                text={text13}
                img={img13}
              />
            </Row>
          </Col>

          <Col md={12} id="team8">
            <Row justify="start">
              <TeamCard
                title={title8}
                subTitle={subTitle8}
                text={text8}
                img={img8}
              />
            </Row>
          </Col>

          <Col md={12} id="team10">
            <Row justify="start">
              <TeamCard
                title={title10}
                subTitle={subTitle10}
                text={text10}
                img={img10}
              />
            </Row>
          </Col>
          <Col md={12} id="team11">
            <Row justify="start">
              <TeamCard
                title={title11}
                subTitle={subTitle11}
                text={text11}
                img={img11}
              />
            </Row>
          </Col>
          <Col md={12} id="team12">
            <Row justify="start">
              <TeamCard
                title={title12}
                subTitle={subTitle12}
                text={text12}
                img={img12}
              />
            </Row>
          </Col>
        </Row>
      </div>

      <div className="team-container">
        <Row justify="start">
          <div className="team-header-wrapper">Past Contributors</div>
        </Row>
        <Row justify="space-between">
          <Col md={12} id="team6">
            <Row justify="start">
              <TeamCard
                title={title6}
                subTitle={subTitle6}
                /*text={text6}*/
                img={img6}
              />
            </Row>
          </Col>
          <Col md={12} id="team7">
            <Row justify="start">
              <TeamCard
                title={title7}
                subTitle={subTitle7}
                /*text={text7}*/
                img={img7}
              />
            </Row>
          </Col>
          <Col md={12} id="team9">
            <Row justify="start">
              <TeamCard
                title={title9}
                subTitle={subTitle9}
                /*text={text9}*/
                img={img9}
              />
            </Row>
          </Col>
        </Row>
      </div>
      <BackTop />
      <Footer />
    </>
  );

  return returnValue;
}

export default Team;
