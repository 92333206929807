import "leaflet/dist/leaflet.css";
import { Row, Col, Switch, Card } from "antd";
import FilterRadio from "../components/FilterRadio";
/// Only M1s are drawed.
// let a = [];
// let mapDataUse = mapData;
// for (const key in mapDataUse.features) {
//   if (mapDataUse.features[key].properties.lsad === "M1") {
//     a.push(mapDataUse.features[key]);
//     console.log(mapDataUse.features[key].properties.lsad);
//   }
// }
// mapDataUse.features = a;

const Filters = ({
  // setYear,
  setAgeGroup,
  // setDenominatorFilter,
  setInsuranceFilter,
  setReportFilter,
  reportFilter,
  setShowCenters,
}) => {
  // const denominatorOptions = [
  //   { label: "0", value: 0 },
  //   { label: "4", value: 4 },
  //   { label: "10", value: 10 },
  //   { label: "15", value: 15 },
  //   { label: "20", value: 20 },
  // ];

  const insuranceOptions = [
    { label: "All", value: "All" },
    { label: "Privately Managed", value: "Privately Managed" },
    { label: "Medicaid", value: "Medicaid" },
    // { label: "Both for test", value: "Both" },
  ];

  const ageGroupOptions = [
    { label: "All", value: "All" },
    { label: "18 to 44", value: "18-44" },
    { label: "45 to 64", value: "45-64" },
    { label: "65+", value: "65+" },
  ];
  // const timeHorizonOptions = [
  //   { label: "All", value: "All" },
  //   { label: "2017", value: "2017" },
  //   { label: "2018", value: "2018" },
  //   { label: "2019", value: "2019" },
  //   { label: "2020", value: "2020" },
  //   { label: "2021", value: "2021" },
  //   { label: "2022", value: "2022" },
  // ];

  const reportOptions = [
    { label: "CC Burden", value: "CC Rates" },
    { label: "r/mCC Burden", value: "r/mCC Rates" },
    { label: "CC Cases", value: "CC Cases" },
    { label: "r/mCC Cases", value: "r/mCC Cases" },
    {
      label: "CC Screening Rate",
      value: "Screen Rate",
    },
    { label: "Poverty Rate", value: "Poverty" },
  ];

  let showDenominator = true;
  if (reportFilter !== "r/mCC Rates") {
    showDenominator = false;
    //show denominator only when r/mCC Rates is chosen
  }

  return (
    <div className="filters-wrapper">
      <Card>
        <Row gutter={0} align="top" justify="start">
          <Col xs={17} sm={17} md={32} lg={8} className="filters-column">
            <FilterRadio
              title="Report Filter"
              options={reportOptions}
              defaultValue={"r/mCC Rates"}
              setValue={setReportFilter}
              type={""} //either default radio or button
            />
            <hr className="hr-btw-filters" />
          </Col>
          <Col xs={17} sm={17} md={32} lg={6} className="filters-column">
            <FilterRadio
              title="Insurance Type"
              options={insuranceOptions}
              defaultValue={"All"}
              setValue={setInsuranceFilter}
            />
            {/*<TimelineSlider setYear={setYear} />*/}
            <hr className="hr-btw-filters" />
          </Col>
          {/*
          <Col xs={17} sm={17} md={24} lg={6} className="filters-column">
            <FilterRadio
              title="Time Horizon"
              options={timeHorizonOptions}
              defaultValue={"All"}
              setValue={setYear}
            />
            <TimelineSlider setYear={setYear} />
            <hr className="hr-btw-filters" />
          </Col>
          */}
          <Col xs={17} sm={17} md={32} lg={6} className="filters-column">
            <FilterRadio
              title="Age Group"
              options={ageGroupOptions}
              defaultValue={"All"}
              setValue={setAgeGroup}
            />
            <hr className="hr-btw-filters" />
          </Col>
          {/* 
          <Col xs={17} sm={17} md={24} lg={6} className="filters-column">
            <FilterRadio
              title="Minimum number of CC cases"
              options={denominatorOptions}
              defaultValue={4}
              setValue={setDenominatorFilter}
              visible={showDenominator}
            />
          </Col>
         */}
          <Col xs={17} sm={17} md={24} lg={4} className="filters-column">
            <div
              style={{
                fontSize: "15px",
                fontFamily: "Arial",
                fontWeight: " bold",
              }}
            >
              Brachytherapy Centers
            </div>
            <Switch size="small" onChange={setShowCenters} defaultChecked />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Filters;
