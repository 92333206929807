import { BackTop, Row, Col } from "antd";

import Footer from "./Footer";
import HeaderNew from "./HeaderNew";

function Methodology() {
  const returnValue = (
    <>
      <HeaderNew selected="methodology" />
      <div className="content-container">
        <Row justify="start">
          <Col md={20}>
            <div className="team-header-wrapper">Methodology</div>
          </Col>
        </Row>
        <Row justify="start">
          <Col md={20}>
            <Row justify="start">
              <p>
                The inputs to the web-based tool were extracted through a
                retrospective analysis of a comprehensive claims database
                <sup>1</sup> from 2017-2022 using previously validated
                methodology.<sup>2,3</sup> We then calculated the r/mCC burden
                as the ratio of r/mCC cases over cervical cancer (CC) cases for
                each of the >850 ZIP-3s considered. The calculated
                ZIP-3-specific r/mCC burden was visualized in an interactive US
                map, which is publicly available on an interactive website. The
                online interactive tool allowed the users to filter the results
                by year, age group, and insurance type at the ZIP-3 level.
              </p>
              <p>
                This study used de-identified claims of patients across all US
                states and DC from over 165 million unique patients covered by
                Commercial, Medicaid, or Medicare Advantage plans. The study
                included women who were 18 years and above, continuously
                enrolled in a health plan for at least six months prior to and
                three months after index diagnosis, and had no claims of
                hysterectomy prior to the first observed CC diagnosis. A
                screening-eligible enrollee was defined as a female enrollee
                between the ages of 21 and 64 who was continuously enrolled for
                an entire year with a 30-day allowance gap and who did not
                previously have a hysterectomy. A patient was counted as being
                screened during a year of interest if she was between the ages
                of 21 and 64 and had cervical cytology performed within the
                previous three years, between the ages of 30 and 64 and had
                cervical hrHPV testing performed within the previous five years,
                or between the ages of 30 and 64 and had cervical cytology/hrHPV
                co-testing performed within the previous five years.<sup>4</sup>
              </p>
              <p>
                {" "}
                To identify the geographic distribution of patients with
                cervical cancer, the number of women who had a diagnosis of
                cervical cancer were counted for each ZIP-3 separately. CC
                diagnosis was identified based on the presence of a relevant ICD
                code for CC during a given calendar year on at least one
                inpatient claim or two outpatient claims. Data were stratified
                by age group (18-44 years, 45-64 years, 65 years and above) to
                allow for estimation of age-adjusted rates (per 100,000). Data
                were also stratified by insurance type to allow for estimation
                of rates by coverage type. Data were censored in a ZIP-3 for
                which counts were less than or equal to 3. Brachytherapy center
                sites, which serve as a proxy for guideline-conforming care for
                earlier-stage disease, can be overlayed with CC and r/mCC rates.
                Poverty rate, which we define as the proportion of families with
                income ≤200% of the federal poverty level, was collected from
                the 2018-2022 5-year American Community Survey.
              </p>
              {/* <p>
                {" "}
                To identify the geographic, MSA-level distribution/clustering of
                patients diagnosed with r/mCC in proportion to CC diagnosed
                patients, the CC diagnosed women served as the at-risk
                population (denominator). Similar to CC distribution analysis,
                the data for r/mCC patients were stratified by age group (18-21
                years, 22-64 years, 65 years and above) to allow estimation of
                age-adjusted rates (per 1000). Based on these prior data, we
                then applied an iterative step-wise procedure for identification
                of r/mCC. Data entries belonging to non-MSAs were excluded from
                analysis. In reporting r/mCC rates, we included only those MSAs
                with at least 10 diagnosed r/mCC cases in each year considered.
              </p> */}
            </Row>
          </Col>
        </Row>
        <Row justify="start">
          <Col md={20}>
            <div className="team-header-wrapper">Definitions</div>
          </Col>
        </Row>
        <Row justify="start">
          <Col md={20}>
            <Row justify="start">
              <p>
                CC Burden: Prevalent number of CC diagnoses per 100,000 eligible
                female enrollees. <br /> r/mCC Burden: Proportion of patients
                with CC who initiated systemic therapy. <br /> Poverty Rate:
                Proportion of families with income ≤200% of the federal poverty
                level.
              </p>
            </Row>
          </Col>
        </Row>
        <br />

        <Row justify="start">
          <Col md={20}>
            <p className="ref-text">
              {" "}
              1. The database is Komodo Healthcare Map.
              <br />
              2. Musa FB, Brouwer E, Ting J, Schwartz NRM, Surinach A, Bloudek
              L, et al. Trends in treatment patterns and costs of care among
              patients with advanced stage cervical cancer. Gynecol Oncol.
              2022;164(3):645-50.
              <br />
              3. Leath CA, III, Nysenbaum J, Ting J, Zhang YJ, Fiori A, Pauly N.
              Patterns of care and health care resource use among
              Medicaid-enrolled women with recurrent or metastatic cervical
              cancer. J Manag Care Spec Pharm. 2023;29(5):490-8.
              <br />
              4. CC screening rate is not defined for the 65+ age group in the
              interactive tool.
            </p>
          </Col>
        </Row>
      </div>
      <BackTop />
      <Footer />
    </>
  );

  return returnValue;
}

export default Methodology;
